import agendaStyle from './Agenda.module.css'
import agendaIcon from '../assets/various/agenda-icon.svg'
function Agenda() {
    return(
        <div className={agendaStyle.mainContainer}>
            <div className={agendaStyle.headerContainer}>
                <img src={agendaIcon} className={agendaStyle.icon}/>
                <div className={agendaStyle.titleContainer}>
                    <h2 style={{fontWeight: 'normal'}}>Agenda</h2>
                </div>
            </div>
            <div className={agendaStyle.agendaContainer}>
                <div className={agendaStyle.leftColumn}>
                    <h2 className={agendaStyle.title}>H 14:30 - 15:00</h2>
                    <h2 className={agendaStyle.subtitle}>Accredito e Welcome Coffee</h2>

                    <h2 className={agendaStyle.title}>H 15:00 - 15:30</h2>
                    <h2 className={agendaStyle.subtitle}>Opening Speech [E-talk]</h2>
                    <h3 className={agendaStyle.subtitle}>Fabrizio Ruggiero</h3>

                    <h2 className={agendaStyle.title}>H 15:30 - 15:50</h2>
                    <h2 className={agendaStyle.subtitle}>E-Talk 1</h2>
                    <h3 className={agendaStyle.subtitle}>azienda</h3>

                    <h2 className={agendaStyle.title}>H 15:50 - 16:10</h2>
                    <h2 className={agendaStyle.subtitle}>Special Guest 1</h2>

                    <h2 className={agendaStyle.title}>H 16:10 - 16:30</h2>
                    <h2 className={agendaStyle.subtitle}>E-Talk 2</h2>
                    <h3 className={agendaStyle.subtitle}>azienda</h3>
                </div>
                <div className={agendaStyle.rightColumn}>
                    <h2 className={agendaStyle.title}>H 16:30 - 16:50</h2>
                    <h2 className={agendaStyle.subtitle}>Special Guest 2</h2>

                    <h2 className={agendaStyle.title}>H 16:50 - 17:20</h2>
                    <h2 className={agendaStyle.subtitle}>Coffee Break</h2>

                    <h2 className={agendaStyle.title}>H 17:20 - 17:40</h2>
                    <h2 className={agendaStyle.subtitle}>E-Talk 3</h2>
                    <h3 className={agendaStyle.subtitle}>azienda</h3>

                    <h2 className={agendaStyle.title}>H 17:40 - 18:30</h2>
                    <h2 className={agendaStyle.subtitle}>Roundtable</h2>

                    <h2 className={agendaStyle.title}>H 18:30 - 18:45</h2>
                    <h2 className={agendaStyle.subtitle}>Closing</h2>

                    <h2 className={agendaStyle.title}>H 18:45 - 21:00</h2>
                    <h2 className={agendaStyle.subtitle}>Light Aperitif & Networking</h2>
                </div>
            </div>

        </div>
    )
}

export default Agenda;